import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faqs = ({ faqs }) => {
    return (
        <div className="h-screen snap-start flex flex-col items-center justify-center bg-[#000] gap-5" id="faq">
            <h1 className="text-2xl font-semibold text-[#d56900]">Frequently Asked Questions</h1>
            <div className="px-2 max-w-4xl w-full h-2/3 overflow-y-auto">
                {/* use acccordian to show all faqs question and answer */}
                {faqs?.length ?
                    faqs?.map((faq, index) => 
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            {faq.question}
                        </AccordionSummary>
                        <AccordionDetails>
                                {faq.answer}
                        </AccordionDetails>
                    </Accordion>
                    ) : <div className="h-full flex items-center justify-center text-white">Coming Soon!</div>
                }
            </div>
        </div>
    );
    }

export default Faqs;