import React from 'react'
import YellowDancer from '../assets/dancer2.png';

const About = ({ data }) => {
  return (
    <div className='h-screen flex justify-center item-center bg-dancer2 bg-center bg-cover bg-blend-overlay bg-[#222224] sm:bg-black items-center snap-start' id='about'>
        <div className='flex flex-col justify-center gap-3 sm:w-2/3 sm:pl-20 h-4/5 no-scrollbar'>
        <p className='sm:tracking-widest tracking-normal sm:text-3xl text-2xl p-3 max-sm:text-center text-[#d56900]'>{data?.header}</p>
        {/* <p className='text-lg p-3 sm:text-2xl font-light'>{data?.content}</p> */}
        <div className='text-lg p-3 font-light overflow-y-auto scrollbar-hide text-justify' dangerouslySetInnerHTML={{ __html: data?.content }} />
        </div>
        <div className='hidden sm:flex sm:1/2'>
            <img src={YellowDancer} alt='dancer-2'/>
        </div>
    </div>
  )
}

export default About